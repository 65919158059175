export interface Data {
  name: string;
  percent: string;
  address: string;
  logo1: string;
  logo2: string;
}

const maindata: Data[] = [
  {
    name: "UNI/ETH",
    percent: "0.3",
    address: "0x5f238e8b764457dc2d67ec61680562fe834d5560",
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "USDT/ETH",
    percent: "0.3",
    address: "0x5faeef221d656eac85fb9a659a0bf162e36facbb",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "DAI/ETH",
    percent: "0.3",
    address: "0x013401aa0d52e2a6142b9ab697442746700b1876",
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "ETH/USDC",
    percent: "0.3",
    address: "0x75416d6102dc0a10b0bcb129518f00f54950e598",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    name: "ETH/WBTC",
    percent: "0.3",
    address: "0x8c5713ea8dbefec4a9f206c19af03c612b79fa56",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
  {
    name: "USDT/USDC",
    percent: "1",
    address: "0x233cee2009a9a6c160dc4c7e2c42cd6668f741a7",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    name: "USDT/UNI",
    percent: "0.3",
    address: "0x1d74763f8519d5338a3febba54d59787249276a7",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
  },
  {
    name: "USDT/ETH",
    percent: "0.3",
    address: "0x4e6b9ca4bdca9d610d46295ba08805f077dde63a",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "DAI/USDT",
    percent: "0.05",
    address: "0x3635efc7631f87a33b24222671024516318b4f97",
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "PILOT/ETH",
    percent: "0.05",
    address: "0x2b8908af5268f4cfbfeab65eb2415f97150f34d8",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
];

export default maindata;
